
 // @ is an alias to /src
import Navigation from '@/components/partials/Navigation.vue';
import Aside from '@/components/partials/Aside.vue';
import Footer from '@/components/partials/Footer.vue';
import { defineComponent, ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: "DeleteUser",
  components: {
    Navigation,
    Aside,
    Footer
  },
  setup() {
    const store = useStore();
    const links = reactive([
      {
        name: "Remove User",
        url: "user/delete"
      },
    ]);
    const levels = reactive(["JS1", "JS2", "JS3", "SS1", "SS2", "SS3", "STF"]);
    const  statuses = reactive(["student", "staff"]);
    const selections = reactive([]);
    const users = reactive([
      {
        id: 1,
        level: "STF",
        email: "john.doe@mail.com",
        roles: 2,
      },
      {
        id: 2,
        level: "SDT",
        email: "jack.doe@mail.com",
        roles: 0,
      },
      {
        id: 1,
        level: "SDT",
        email: "jane.doe@mail.com",
        roles: 1,
      },
    ]);
    const user = reactive({
      displayName: "John Doe",
      phoneNumber: "+123456789",
      email: "john.doe@email.com",
      password: "********",
      confirm_password: "********",
      reg_id: "0020239",
      level: "",
      status: ""
    });

    const name = ref("User");
    const nav = computed(() => store.getters.nav);

    return { name, nav, links, user, users, levels, statuses, selections};
  },
});
